<template>
    <div id="top" class="mv-container">
        <object :data="mvDecoration" type="image/svg+xml"></object>
        <div class="mv-contents">
            <div class="mv-first">
                <h2>サイト制作をもっと気軽に</h2>
                <p class="english-title">MARUNAGE</p>
                <!-- <p class="japanese-title">まるなげ</p> -->
            </div>
            <div class="mv-second">
                <p>製作費<span>完全無料！</span></p>
                <p>原稿作業も<span>まるなげ！</span></p>
            </div>
            <div class="mv-third">
                <p>クオリティと価格どちらも</p>
                <p>妥協しない<span>月額4980円〜！</span></p>
            </div>
            <div class="mv-badges">
                <img :src="badge01">
                <img :src="badge02">
                <img :src="badge03">
            </div>
            <div class="mv-background-girl">
                <img :src="backgroundGirl">
            </div>
        </div>
    </div>
    <a href="https://lin.ee/CQhW0kV" target="_blank" class="mv-line-container">
        <line-decoration></line-decoration>
        <p>まずは無料で</p>
        <img :src="lineLogo" alt="ラインロゴ">
        <p>相談♪</p>
    </a>
    <template-slider></template-slider>
    <div id="point" class="points-container">
        <div class="section-title section-title-top-left">
            <h3>まるなげ<span>5つのポイント</span></h3>
            <title-background></title-background>
        </div>
        <div class="point-container point-left point05">
            <div class="point-number">
                <p>POINT 01</p>
            </div>
            <div class="point-contents">
                <p class="point-first">全てのホームページで革新的な</p>
                <h4><span>シングルページアプリケーション</span><br class="sp-br">(SPA)を採用！</h4>
                <p class="point-last">従来のストレスや遅延を一掃し、ロード時間を<br class="sp-br">『０秒』に！<br class="pc-br">快適なユーザー体験とビジネスの<br
                        class="sp-br">成約率、売上向上にコミットします</p>
            </div>
        </div>
        <div class="point-container point-right point01">
            <div class="point-number">
                <p>POINT 02</p>
            </div>
            <div class="point-contents">
                <p class="point-first">原稿作成お任せください！</p>
                <h4>ヒアリングシートを記入したら後は、<span>まるなげ！</span></h4>
                <p class="point-last">ホームページ作成でつまづき易い原稿作成。<br>ヒアリングシートを元に原稿作成を代行致します！</p>
                <object :data="point01" type="image/svg+xml"></object>
            </div>
        </div>
        <div class="point-container point-left point02">
            <div class="point-number">
                <p>POINT 03</p>
            </div>
            <div class="point-contents">
                <p class="point-first">圧倒的な『低価格』&『高品質』</p>
                <h4><span>制作費用0円!!</span>月額4980円〜!!</h4>
                <p class="point-last">他社では20万円〜100万のクオリティのHPを月額4980円〜で作成！<br>月額使用料金の中に保守費用もコミコミ！</p>
                <object :data="point02" type="image/svg+xml"></object>
            </div>
        </div>
        <div class="point-container point-right point03">
            <div class="point-number">
                <p>POINT 04</p>
            </div>
            <div class="point-contents">
                <p class="point-first">豊富なオプションを自由に選択。</p>
                <h4><span>多言語翻訳からGoogleマップ</span>連携まで<br class="sp-br">おまかせください！</h4>
                <p class="point-last">ニュース機能,LINE
                    Lステップ,SNS連結,MEO対策,多言語翻訳,メニュー<br>追従機能,etc...豊富なオプションからお望みのHPを作成できます。<br><span>※一部OPとプランにより、別途費用がかかります。</span>
                </p>
                <object :data="point03" type="image/svg+xml"></object>
            </div>
        </div>
        <div class="point-container point-left point04">
            <div class="point-number">
                <p>POINT 05</p>
            </div>
            <div class="point-contents">
                <p class="point-first">終わりのないサポートをお約束します。</p>
                <h4>制作後の更新も<span>無料！</span></h4>
                <p class="point-last">制作後も手厚いサポート！！<br>まるなげでは、制作後のデータ更新も無償で対応いたします。<br><span>※無償での更新には、制限がございます。</span></p>
                <object :data="point01" type="image/svg+xml"></object>
            </div>
        </div>
    </div>
    <div class="manga-container">
        <img :src="manga" alt="まるなげ漫画">
    </div>
    <body-contact></body-contact>
    <div class="compare-container">
        <div class="section-title section-title-top-right">
            <h3>まるなげ<span>と他社の比較</span></h3>
            <title-background></title-background>
        </div>
        <div class="table-container" :style="{backgroundImage: 'url('+tableBackground+')'}">
            <div class="table-col title-col">
                <div class="table-title first-row">
                    <p>納品期間</p>
                </div>
                <div class="table-title second-row">
                    <p>クオリティ</p>
                </div>
                <div class="table-title third-row">
                    <p>費用</p>
                </div>
            </div>
            <div class="table-col body-col">
                <div class="title-row">
                    <p>一般制作会社</p>
                    <img :src="titleRowBackground">
                </div>
                <div class="body-row first-row">
                    <img :src="singleCircle">
                    <p>早くても2~3ヶ月程度</p>
                </div>
                <div class="body-row second-row">
                    <img :src="singleCircle">
                    <p>オリジナルデザイン<br class="sp-br">での<br class="pc-br">作成</p>
                </div>
                <div class="body-row third-row">
                    <img class="gray-cross" :src="grayPlus">
                    <p>製作費のみで数<br class="sp-br">十万円<br class="pc-br">〜数百万円<br class="sp-br">別途月数万円<br class="pc-br">の<br
                            class="sp-br">ランニングコスト<br class="sp-br">も発生</p>
                </div>
            </div>
            <div class="table-col body-col">
                <div class="title-row">
                    <p>格安制作会社</p>
                    <img :src="titleRowBackground">
                </div>
                <div class="body-row first-row">
                    <img :src="blueTriangle">
                    <p>調整する事は<br class="sp-br">可能だが、<br class="pc-br">クオリティが落ちる事も。</p>
                </div>
                <div class="body-row second-row">
                    <img class="gray-cross" :src="grayPlus">
                    <p>簡易的なテンプレートのみ</p>
                </div>
                <div class="body-row third-row">
                    <img class="gray-cross" :src="grayPlus">
                    <p>月額や製作費は<br class="sp-br">安価だが、<br class="pc-br">別途<br class="sp-br">保守費等で請求される<br
                            class="pc-br">事がほとんど</p>
                </div>
            </div>
            <div class="table-col body-col focused-col">
                <div class="title-row">
                    <p>まるなげ</p>
                    <img :src="focusRowBackground">
                </div>
                <div class="body-row first-row">
                    <img :src="doubleCircle">
                    <p>最短<span>5営業日</span>程度から<br class="pc-br">製作が可能。</p>
                </div>
                <div class="body-row second-row">
                    <img :src="doubleCircle">
                    <p><span>高品質</span>テンプレート or<br><span>オリジナル</span>デザイン</p>
                </div>
                <div class="body-row third-row">
                    <img :src="doubleCircle">
                    <p>HP制作費<span>0円</span><br>月額<span>4980円〜</span><br>保守費用も<br class="sp-br">コミコミ！！</p>
                </div>
            </div>
        </div>
    </div>
    <div id="flow" class="flows-container">
        <div class="section-title section-title-top-left">
            <h3>まるなげ<span>ご利用の流れ</span></h3>
            <title-background></title-background>
        </div>
        <div class="flow-container flow-left flow01">
            <div class="flow-number">
                <p>1</p>
            </div>
            <div class="flow-box">
                <img :src="flow01" alt="お問い合わせ">
                <div class="flow-text">
                    <h4>お問い合わせ</h4>
                    <p>不明点を確認したい、見積もりが欲しいなど、<br class="sp-br tablet-br">お問い合わせフォーム、<br class="pc-br tablet-br">電話又はLINEから<br class="sp-br tablet-br">お気軽にお問い合わせ下さい。</p>
                </div>
                <object :data="point01" type="image/svg+xml"></object>
            </div>
        </div>
        <div class="flow-container flow-right flow02">
            <div class="flow-number">
                <p>2</p>
            </div>
            <div class="flow-box">
                <img :src="flow02" alt="ヒアリング・打ち合わせ">
                <div class="flow-text">
                    <h4>ヒアリング・打ち合わせ</h4>
                    <p>まるなげサービスの説明や、ヒアリングシート<br class="sp-br tablet-br">の作成、<br class="pc-br tablet-br">お客様の事業内容、ご要望等を<br class="sp-br tablet-br">打ち合わせし明確化いたします。</p>
                </div>
                <object :data="point01" type="image/svg+xml"></object>
            </div>
        </div>
        <div class="flow-container flow-left flow03">
            <div class="flow-number">
                <p>3</p>
            </div>
            <div class="flow-box">
                <img :src="flow03" alt="ホームページの制作・ご契約">
                <div class="flow-text">
                    <h4>ホームページの制作・ご契約</h4>
                    <p>お見積りにご納得頂けましたらご契約。<br class="sp-br tablet-br">その後、<br class="pc-br tablet-br">お客様から頂いた情報を元に、<br class="sp-br tablet-br">ホームページ制作に入ります。</p>
                </div>
                <object :data="point03" type="image/svg+xml"></object>
            </div>
        </div>
        <div class="flow-container flow-right flow04">
            <div class="flow-number">
                <p>4</p>
            </div>
            <div class="flow-box">
                <img :src="flow02" alt="ホームページ公開">
                <div class="flow-text">
                    <h4>ホームページ公開</h4>
                    <p>ホームページ公開前にお客様に最終確認をして<br class="sp-br tablet-br">頂き、調整の必要が<br class="pc-br tablet-br">あれば微調整し、<br class="sp-br tablet-br">公開いたします。</p>
                </div>
            </div>
        </div>
    </div>
    <body-contact></body-contact>
    <div id="plan" class="plans-container">
        <div class="section-title section-title-bottom-right">
            <h3>まるなげ<span>プラン料金一覧</span></h3>
            <title-background></title-background>
        </div>
        <div class="plan-container" :style="{ backgroundColor: planColors[selectedPlan] , backgroundImage: 'url('+tableBackground+')'}">
            <div class="plans-tab-container">
                <div class="plans-tab">
                    <div class="plans-tab-item active" @click="changePlan(0), setToActive()">
                        <h4>シンプルプラン</h4>
                    </div>
                    <div class="plans-tab-item inactive" @click="changePlan(1), setToActive()">
                        <h4><span><img :src="star">１番人気<img :src="star"></span><br>スタンダードプラン</h4>
                    </div>
                    <div class="plans-tab-item inactive" @click="changePlan(2), setToActive()">
                        <h4>オリジナルオーダープラン</h4>
                    </div>
                    <div class="plans-tab-item inactive" @click="changePlan(3), setToActive()">
                        <h4>ECサイトプラン</h4>
                    </div>
                </div>
            </div>
            <div class="plan-contents">
                <div class="plan-details">
                    <p class="plan-description">{{ planDescriptions[selectedPlan] }}</p>
                    <div class="plan-preview">
                        <img :src="planPreviews[selectedPlan]">
                        <div class="plan-details-row">
                            <div class="plan-details-first-row">
                                <div class="plan-price">
                                    <p>HP制作費</p>
                                    <p class="plan-price-number">0円</p>
                                </div>
                                <div class="plan-price">
                                    <p>月額</p>
                                    <p class="plan-price-number">{{ planPrices[selectedPlan] }}</p>
                                </div>
                            </div>
                            <div class="plan-details-second-row">
                                <div class="plan-pages" :style="{ borderBottomColor: planColors[selectedPlan] }">
                                    <p>{{ planPages[selectedPlan] }}</p>
                                </div>
                                <div class="plan-pages" :style="{ borderBottomColor: planColors[selectedPlan] }">
                                    <p v-if="planCanAddPages[selectedPlan]">ページ追加も可能</p>
                                </div>
                                <div class="price-tax">
                                    <p>{{ planTaxes[selectedPlan] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="plan-tags">
                    <div class="plan-tag" v-for="tag in planTagsNum[selectedPlan]" :key="tag"
                        :style="{ width: setTagWidth() }">
                        <img :src="planTags[tag].image">
                        <p v-html="planTags[tag].name"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <simulator></simulator> -->
    <div class="sub-title-container">
        <h3>レビュー</h3>
    </div>
    <div class="review-container">
        <div class="review" v-for="item in reviews" :key="item">
            <div class="review-avatar">
                <img :src="item.avatar">
            </div>
            <div class="review-text">
                <div class="review-rating">
                    <div class="review-name">
                        <p v-html="item.name"></p>
                    </div>
                    <div class="review-stars">
                        <img :src="star" v-for="index in item.star" :key="index">
                    </div>
                    <div class="review-date">
                        <p>{{ item.date }}</p>
                    </div>
                </div>
                <div class="review-title">
                    <p>{{ item.title }}</p>
                </div>
                <div class="review-body">
                    <p>{{ item.body }}</p>
                </div>
            </div>
        </div>
    </div>
    <div id="question" class="questions-container">
        <div class="sub-title-container">
            <h3>よくある質問</h3>
        </div>
        <template v-for="item in questionAnswers" :key="item">
            <div class="question-container">
                <p class="first-letter">Q</p>
                <p v-html="item.question" class="question"></p>
            </div>
            <div class="answer-container">
                <p class="first-letter">A</p>
                <p v-html="item.answer" class="answer"></p>
            </div>
        </template>
    </div>
</template>
<script>
import badge01 from '../assets/badge01.png';
import badge02 from '../assets/badge02.svg';
import badge03 from '../assets/badge03.png';
import backgroundGirl from '../assets/main-girl.png';
import lineLogo from '../assets/line-logo.png';
import manga from '../assets/manga.png';
import rightArrow from '../assets/right-arrow.png';
import singleCircle from '../assets/single-circle.png';
import doubleCircle from '../assets/double-circle.png';
import blueTriangle from '../assets/blue-triangle.png';
import grayPlus from '../assets/gray-plus.png';
import titleRowBackground from '../assets/title-row-bg.png';
import focusRowBackground from '../assets/focus-row-bg.png';
import flow01 from '../assets/flow01.jpg';
import flow02 from '../assets/flow02.jpg';
import flow03 from '../assets/flow03.jpg';
import flow04 from '../assets/flow04.jpg';
import plan01 from '../assets/plan01.png';
import plan02 from '../assets/plan02.png';
import plan03 from '../assets/plan03.png';
import plan04 from '../assets/plan04.png';
import tag01 from '../assets/tag01.png';
import tag02 from '../assets/tag02.png';
import tag03 from '../assets/tag03.png';
import tag04 from '../assets/tag04.png';
import tag05 from '../assets/tag05.png';
import tag06 from '../assets/tag06.png';
import tag07 from '../assets/tag07.png';
import tag08 from '../assets/tag08.png';
import tag09 from '../assets/tag09.png';
import tag10 from '../assets/tag10.png';
import avatar01 from '../assets/avatar01.jpg';
import avatar02 from '../assets/avatar02.jpg';
import avatar03 from '../assets/avatar03.jpg';
import star from '../assets/star.png';
import tableBackground from '../assets/table-bg.png';

import contactBacground from '../assets/contact-bg.svg';
import mvDecoration from '../assets/mv-deco.svg';
import point01 from '../assets/point01.svg';
import point02 from '../assets/point02.svg';
import point03 from '../assets/point03.svg';

import bodyContact from '../components/BodyContact.vue';
// import simulator from '../components/Simulator.vue';
import lineDecoration from '../components/LineDecoration.vue';
import titleBackground from '../components/TitleBackground.vue';
import templateSlider from '../components/Template.vue';

export default {
    name: 'the-body',
    data() {
        return {
            badge01: badge01,
            badge02: badge02,
            badge03: badge03,
            backgroundGirl: backgroundGirl,
            mvDecoration: mvDecoration,
            lineLogo: lineLogo,
            point01: point01,
            point02: point02,
            point03: point03,
            manga: manga,
            rightArrow: rightArrow,
            contactBacground: contactBacground,
            singleCircle: singleCircle,
            doubleCircle: doubleCircle,
            blueTriangle: blueTriangle,
            grayPlus: grayPlus,
            titleRowBackground: titleRowBackground,
            focusRowBackground: focusRowBackground,
            flow01: flow01,
            flow02: flow02,
            flow03: flow03,
            flow04: flow04,
            tag01: tag01,
            tag02: tag02,
            tag03: tag03,
            tag04: tag04,
            tag05: tag05,
            tag06: tag06,
            tag07: tag07,
            tag08: tag08,
            tag09: tag09,
            tag10: tag10,
            star: star,
            avatar01: avatar01,
            avatar02: avatar02,
            avatar03: avatar03,
            tableBackground: tableBackground,
            selectedPlan: 0,
            planDescriptions: [
                "シンプル・スピーディーに圧倒的な低価格で貴社ホームページを制作",
                "高品質テンプレートを使用＋ニュースブログ機能付き！",
                "テンプレートを使わずオリジナルHPを制作",
                "自社製品をオンラインで簡単販売！"
            ],
            planPreviews: [
                plan01,
                plan02,
                plan03,
                plan04
            ],
            planPrices: [
                "4,980円",
                "7,980円",
                "12,800円",
                "19,800円"
            ],
            planTaxes: [
                "※税込5,478円",
                "※税込8,778円",
                "※税込14,080円",
                "※税込21,780円"
            ],
            planPages: [
                "TOP+5ページの作成",
                "TOP+7ページの作成",
                "TOP+7ページの作成",
                "TOP+7ページの作成",
            ],
            planCanAddPages: [
                false,
                true,
                true,
                true
            ],
            planTags: [
                {
                    "name": "高品質なテンプレート",
                    "image": tag01
                },
                {
                    "name": "内部SEO対策",
                    "image": tag02
                },
                {
                    "name": "スマートフォン対応",
                    "image": tag03
                },
                {
                    "name": "お問い合わせフォームの<br class='tablet-br'>設置",
                    "image": tag04
                },
                {
                    "name": "サーバー・<br class='sp-br'>ドメイン管理",
                    "image": tag05
                },
                {
                    "name": "メールアドレス<br class='sp-br'>作成可能",
                    "image": tag06
                },
                {
                    "name": "ニュースブログ機能",
                    "image": tag07
                },
                {
                    "name": "カート機能",
                    "image": tag08
                },
                {
                    "name": "アカウント機能",
                    "image": tag09
                },
                {
                    "name": "決済機能",
                    "image": tag10
                }
            ],
            planTagsNum: [
                [
                    0, 1, 2, 3, 4, 5
                ],
                [
                    0, 1, 2, 3, 4, 5, 6
                ],
                [
                    0, 1, 2, 3, 4, 5, 6
                ],
                [
                    0, 1, 2, 3, 4, 5, 6, 7, 8, 9
                ]
            ],
            planColors: [
                "#FF8F07",
                "#FB7314",
                "#FB5314",
                "#E43000"
            ],
            questionAnswers: [
                {
                    "question": "ホームページ制作時間は<br class='sp-br'>どのくらいでしょうか？",
                    "answer": "ページ数、内容、規模感によって様々ですが、目安として、シンプルプラン<br class='sp-br'>7日、<br class='pc-br'>スタンンダードプラン2週間、<br class='sp-br'>オリジナルオーダー1~2ヶ月,<br class='sp-br'>ECサイトプラン<br class='pc-br'>2ヶ月営業日程度で<br class='sp-br'>作成可能です。"
                },
                {
                    "question": "ホームページの知識が0でも<br class='sp-br'>大丈夫ですか？",
                    "answer": "可能です。ご安心ください。<br class='sp-br'>初めて利用ホームページを制作される<br class='sp-br'>お客様も数多くおります。"
                },
                {
                    "question": "現在契約している所から乗り<br class='sp-br'>換える事は可能でしょうか？",
                    "answer": "お任せください！現在利用中の<br class='sp-br'>ドメインやメールアドレスを<br class='sp-br'>引き継ぐ事も可能です。<br><span>※一部ドメインは対応出来かねる場合もございます。</span>"
                },
                {
                    "question": "他社で断られた業種です。<br class='sp-br'>依頼する事は可能でしょうか？",
                    "answer": "はい、可能です！<br class='sp-br'>どんな業種でもお任せください！<br><span>※別途料金がかかる場合がございます。</span>"
                },
                {
                    "question": "気に入らない場合はすぐに<br class='sp-br'>解約できますか？",
                    "answer": "まるなげでは、契約期間、違約金等の縛りはございません。<br class='sp-br'>いつでもご解約する事が可能です。<br><span>※解約月内のご料金はかかります。また悪質な場合、<br class='sp-br'>制作費を請求させていただく場合がございます。</span>"
                },
                {
                    "question": "ホームページ制作後、<br class='sp-br'>修正などの対応もできますか？",
                    "answer": "まるなげでは、毎月一回までの<br class='sp-br'>修正が無料です。画像やテキストの<br class='sp-br'>差し替えなど、ご連絡ください。<br><span>※修正内容によっては別途ご相談となります。</span>"
                },
                {
                    "question": "解約後ホームページは頂けますか？",
                    "answer": "解約後はホームページの稼働を<br class='sp-br'>停止させて頂きます。買取での<br class='sp-br'>引き取り等ご相談は可能です。<br><span>※但し、ホームページの所有権は弊社に帰属致します。</span>"
                },
                {
                    "question": "見積もりは費用がかかりますか？",
                    "answer": "お見積もりは無料です。<br class='sp-br'>お気軽にお問い合わせください。"
                }
            ],
            reviews: [
                {
                    "avatar": avatar01,
                    "date": "2023/08/01",
                    "star": 5,
                    "name": "宮越 楓 <span>様</span>",
                    "title": "驚くほど手軽に素晴らしいHPを作成",
                    "body": "初めてHP作成を依頼しましたが、驚くほど手軽に素晴らしいHPができました。簡単な指示だけで、後はすべてまるなげ。素早く原稿を作成してくれたので、時間と手間が大幅に節約できました。"
                },
                {
                    "avatar": avatar02,
                    "date": "2023/06/15",
                    "star": 5,
                    "name": "エンタメ業界 A社 <span>様</span>",
                    "title": "満足度200％！最高のサービスです",
                    "body": "HP作成サービスは満足度200％です！提供されたHPは予想以上に素晴らしく、デザインから機能まで文句なしです。担当者の方々も親切で、制作後のサポートも手厚くて感謝しています！"
                },
                {
                    "avatar": avatar03,
                    "date": "2023/05/22",
                    "star": 5,
                    "name": "飲食店 B社 <span>様</span>",
                    "title": "プロフェッショナルなサービスに感動",
                    "body": "プロフェッショナルなサービスを提供していただけました。要件に対する理解が深く、迅速に対応していただけたので、スムーズなプロジェクト進行ができました。"
                }
            ]
        }
    },
    components: {
        "body-contact": bodyContact,
        // "simulator": simulator,
        "line-decoration": lineDecoration,
        "title-background": titleBackground,
        "template-slider": templateSlider
    },
    mounted() {

    },
    methods: {
        changePlan: function (planNum) {
            this.selectedPlan = planNum;
        },
        setToActive() {
            let tabs = document.getElementsByClassName("plans-tab-item");
            for (let i = 0; i < tabs.length; i++) {
                tabs[i].classList.remove("active");
                tabs[i].classList.add("inactive");
            }
            tabs[this.selectedPlan].classList.remove("inactive");
            tabs[this.selectedPlan].classList.add("active");
        },
        setTagWidth() {
            let col = 4;
            if (window.screen.width < 640) {
                col = 3;
            }
            else {
                if (this.planTagsNum[this.selectedPlan].length < 10) {
                    col = 4;

                }
                else {
                    col = 5;
                }
            }

            let width = 100 / col;

            return width + "%";
        },
    },
}
</script>