<template>
    <div id="template" class="template-container">
        <div class="template-preview">
            <div class="template-pics">
                <img id="template_pc" class="template-pc" :src="currentPc" alt="テンプレートPC">
                <div id="template_moving" class="template-moving">
                    <img class="template-laptop" :src="currentLaptop" alt="テンプレートLaptop">
                    <img class="template-tablet" :src="currentTablet" alt="テンプレートTablet">
                    <img class="template-sp" :src="currentSp" alt="テンプレートSP">
                </div>
            </div>
        </div>
        <div class="template-buttons">
            <div id="template_left_btn" class="left-button inactive" @click="moveTemplateLeft"><img :src="leftBtn"></div>
            <div id="template_right_btn" class="right-button" @click="moveTemplateRight"><img :src="rightBtn"></div>
        </div>
        <div class="template-indicator" v-if="isMobileScreen">
            <div class="template-dot">
                <div class="template-dot-inner" v-for="(template, index) in templates" :key="index" :class="{active: currentIndex === index}"></div>
            </div>
        </div>
    </div>
</template>
<script>

import artytechPc from '../assets/artytech-pc.png';
import artytechLaptop from '../assets/artytech-laptop.png';
import artytechTablet from '../assets/artytech-tablet.png';
import artytechSp from '../assets/artytech-sp.png';
import correctPc from '../assets/correct-pc.png';
import correctLaptop from '../assets/correct-laptop.png';
import correctTablet from '../assets/correct-tablet.png';
import correctSp from '../assets/correct-sp.png';
import creaidPc from '../assets/creaid-pc.png';
import creaidLaptop from '../assets/creaid-laptop.png';
import creaidTablet from '../assets/creaid-tablet.png';
import creaidSp from '../assets/creaid-sp.png';
import mjPc from '../assets/mj-pc.png';
import mjLaptop from '../assets/mj-laptop.png';
import mjTablet from '../assets/mj-tablet.png';
import mjSp from '../assets/mj-sp.png';
import yakinikuPc from '../assets/yakiniku-pc.png';
import yakinikuLaptop from '../assets/yakiniku-laptop.png';
import yakinikuTablet from '../assets/yakiniku-tablet.png';
import yakinikuSp from '../assets/yakiniku-sp.png';
import brushedPc from '../assets/brushed-pc.png';
import brushedLaptop from '../assets/brushed-laptop.png';
import brushedTablet from '../assets/brushed-tablet.png';
import brushedSp from '../assets/brushed-sp.png';
import nakamoriPc from '../assets/nakamori-pc.png';
import nakamoriLaptop from '../assets/nakamori-laptop.png';
import nakamoriTablet from '../assets/nakamori-tablet.png';
import nakamoriSp from '../assets/nakamori-sp.png';

export default {
    name: 'the-template',
    components: {

    },
    data() {
        return {
            templates: [
                {
                    'pc': artytechPc,
                    'laptop': artytechLaptop,
                    'tablet': artytechTablet,
                    'sp': artytechSp,
                },
                {
                    'pc': correctPc,
                    'laptop': correctLaptop,
                    'tablet': correctTablet,
                    'sp': correctSp,
                },
                {
                    'pc': creaidPc,
                    'laptop': creaidLaptop,
                    'tablet': creaidTablet,
                    'sp': creaidSp,
                },
                {
                    'pc': mjPc,
                    'laptop': mjLaptop,
                    'tablet': mjTablet,
                    'sp': mjSp,
                },
                {
                    'pc': yakinikuPc,
                    'laptop': yakinikuLaptop,
                    'tablet': yakinikuTablet,
                    'sp': yakinikuSp,
                },
                {
                    'pc': brushedPc,
                    'laptop': brushedLaptop,
                    'tablet': brushedTablet,
                    'sp': brushedSp,
                },
                {
                    'pc': nakamoriPc,
                    'laptop': nakamoriLaptop,
                    'tablet': nakamoriTablet,
                    'sp': nakamoriSp,
                },
            ],
            currentIndex: 0,
            currentPc: null,
            currentLaptop: null,
            currentTablet: null,
            currentSp: null,
            leftBtn: require('../assets/left-button.png'),
            rightBtn: require('../assets/right-button.png'),
            isMobileScreen: false,
        }
    },
    mounted() {
        this.currentPc = this.templates[this.currentIndex].pc;
        this.currentLaptop = this.templates[this.currentIndex].laptop;
        this.currentTablet = this.templates[this.currentIndex].tablet;
        this.currentSp = this.templates[this.currentIndex].sp;
        if(window.innerWidth < 768){
            this.isMobileScreen = true;
        }
    },
    methods: {
        moveTemplateRight(){
            const leftBtn = document.getElementById('template_left_btn');
            const rightBtn = document.getElementById('template_right_btn');
            const templateMoving = document.getElementById('template_moving');
            const templatePc = document.getElementById('template_pc');

            if(this.currentIndex < this.templates.length - 1){
                if(this.currentIndex === 0){
                    leftBtn.classList.remove('inactive');
                }
                this.currentIndex++;
                templateMoving.style.transform = 'translateX(-10%)';
                templatePc.style.transform = 'translateX(-10%)';
                templateMoving.style.opacity = '0';
                templatePc.style.opacity = '0';
                setTimeout(() => {
                    templateMoving.style.transform = 'translateX(0)';
                    templatePc.style.transform = 'translateX(0)';
                    templateMoving.style.opacity = '1';
                    this.currentPc = this.templates[this.currentIndex].pc;
                    this.currentLaptop = this.templates[this.currentIndex].laptop;
                    this.currentTablet = this.templates[this.currentIndex].tablet;
                    this.currentSp = this.templates[this.currentIndex].sp;
                    templatePc.style.opacity = '1';
                }, 500);
            }
            if(this.currentIndex === this.templates.length - 1){
                rightBtn.classList.add('inactive');
            }
        },
        moveTemplateLeft(){
            const leftBtn = document.getElementById('template_left_btn');
            const rightBtn = document.getElementById('template_right_btn');
            const templateMoving = document.getElementById('template_moving');
            const templatePc = document.getElementById('template_pc');
            if(this.currentIndex > 0){
                if(this.currentIndex === this.templates.length - 1){
                    rightBtn.classList.remove('inactive');
                }
                this.currentIndex--;
                templateMoving.style.transform = 'translateX(10%)';
                templatePc.style.transform = 'translateX(10%)';
                templateMoving.style.opacity = '0';
                templatePc.style.opacity = '0';
                setTimeout(() => {
                    templateMoving.style.transform = 'translateX(0)';
                    templatePc.style.transform = 'translateX(0)';
                    templateMoving.style.opacity = '1';
                    this.currentPc = this.templates[this.currentIndex].pc;
                    this.currentLaptop = this.templates[this.currentIndex].laptop;
                    this.currentTablet = this.templates[this.currentIndex].tablet;
                    this.currentSp = this.templates[this.currentIndex].sp;
                templatePc.style.opacity = '1';
                }, 500);
            }
            if(this.currentIndex === 0){
                leftBtn.classList.add('inactive');
            }
        }
    }
}
</script>