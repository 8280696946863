import { createRouter, createWebHistory } from 'vue-router'
import BodyView from '../views/Body.vue'

const routes = [
  {
    path: '/',
    name: 'Body',
    component: BodyView
  },
  {
    path: '/contact-form',
    name: 'ContactForm',
    component: () => import('../views/ContactForm.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () => import('../views/ServiceTerms.vue')
  },
  {
    path: '/thanks',
    name: 'thanks-form',
    component: () => import('../views/ThanksForm.vue')
  },
  {
    path: '/notation',
    name: 'notation-page',
    component: () => import('../views/NotationPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
