<template>
    <div id="contact" class="footer-contact-container" :style="{ backgroundImage: 'url(' + footerContactBg + ')' }">
        <div class="footer-contact-buttons">
            <div class="form-button">
                <router-link to="/contact-form">お問い合わせフォームで相談予約</router-link>
            </div>
            <div class="line-button">
                <a href="https://lin.ee/CQhW0kV" target="_blank"><img :src="lineLogo"> LINE相談</a>
            </div>
        </div>
        <p>お電話でも無料で相談が可能です。<br>お気軽にご相談ください。</p>
        <a href="tel:07089749898" class="the-phone">070-8974-9898</a>
        <p>平日11:00~19:00<br><span>※非通知発信はご遠慮ください。</span></p>
    </div>
    <div class="footer-container">
        <div class="logo-container">
            <img :src="logoWhite" alt="ロゴ">
            <h1>まるなげ</h1>
        </div>
        <div class="footer-title">
            ページ一覧
        </div>
        <div class="footer-list">
            <a :href="currentPage == '/'?'#top':'/#top'">・TOP</a>
            <a :href="currentPage == '/'?'#point':'/#point'">・まるなげPOINT</a>
            <a :href="currentPage == '/'?'#question':'/#question'">・よくある質問</a>
            <a :href="currentPage == '/'?'#flow':'/#flow'">・まるなげご利用の流れ</a>
            <a :href="currentPage == '/'?'#plan':'/#plan'">・プラン一覧</a>
            <a :href="currentPage == '/'?'#template':'/#template'">・テンプレート一覧</a>
            <router-link to="/contact-form">・お問い合わせ</router-link>
        </div>
        <div class="footer-links">
            <a href="https://artytech.jp/" target="_blank">運営会社</a>
            <a href="/notation">特定証取時期法に基づく表記</a>
            <a href="/terms-of-service">利用規約</a>
            <a href="/privacy-policy">プライバシーポリシー</a>
        </div>
    </div>
    <div class="footer-copyrights">
        <p>Copyright © まるなげ All rights reserved.</p>
    </div>
</template>
<script>
import footerContactBg from '../assets/footer-contact-bg.png';
import lineLogo from '../assets/line-logo.png';
import logoWhite from '../assets/logo-white.png';

export default {
    name: 'the-footer',
    data() {
        return {
            footerContactBg: footerContactBg,
            lineLogo: lineLogo,
            logoWhite: logoWhite,
            currentPage: ''
        }
    },
    components: {

    },
    mounted(){
       
    },
    beforeRouteUpdate(to) {
        this.currentPage = to.path;
    },
}
</script>