<template>
  <div class="main-container">
    <div class="background-decoration">
      <object :data="backgroundDecoration" type="image/svg+xml"></object>
    </div>
    <div class="main-contents">
      <the-header/>
      <router-view />
      <the-footer/>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import backgroundDecoration from './assets/bg-deco.svg'

export default {
  name: 'App',
  data() {
    return {
      backgroundDecoration
    }
  },
  components: {
    'the-header': Header,
    'the-footer': Footer
  }
}
</script>

<style>

</style>
