import { createStore } from 'vuex';

export default createStore({
  state: {
    isCancelForm: false,
  },
  getters: {
  },
  mutations: {
    setCancelFormToTrue(state) {
      state.isCancelForm = true;
    },
    setCancelFormToFalse(state) {
      state.isCancelForm = false;
    }
  },
  actions: {
   
  },
  modules: {
  }
})

