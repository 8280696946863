<template>
    <div class="header-container">
        <div class="logo-container">
            <img :src="logo" alt="ロゴ">
            <h1>まるなげ</h1>
        </div>
        <a href="tel:07089749898" class="contact-container the-phone">
            <div class="contact-icon">
                <img :src="phone" alt="電話アイコン">
            </div>
            <div class="context-contents">
                <div class="contact-text">
                    <p>お問い合わせはこちら</p>
                </div>
                <div class="contact-phone">
                    <p>070-8974-9898</p>
                </div>
                <div class="contact-text">
                    <p>平日 </p>
                    <p>11:00～19:00</p>
                </div>
            </div>
        </a>
    </div>
</template>
<script>
import logo from '../assets/logo.png';
import phone from '../assets/phone.png';

export default {
  name: 'the-header',
  data() {
    return {
      logo: logo,
      phone: phone
    }
  },
  components: {
    
  }
}
</script>